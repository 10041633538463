import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: {}, // 用户信息
        service: [], // 服务等级
        orderType: [], // 订单类型
        range: [], // 就诊时间段
    },
    getters: {},
    mutations: {
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo
            localStorage.setItem('USER_INFO', JSON.stringify(userInfo))
        },
        SET_OPTIONS(state, { service, range, order_type }) {
            state.service = service.map(i => ({ text: i.label, value: i.value }))
            state.orderType = order_type.map(i => ({ text: i.label, value: i.value }))
            state.range = range
        }
    },
    actions: {},
    modules: {},
});