import axios from "axios";
import QS from "qs";
import store from "@/store/index";
import { Toast } from "vant";
import router from "./router";

// 环境的切换
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "https://test-api.haidjk.com/api/";
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "https://api.haidjk.com/api/";
}

axios.defaults.timeout = 10000;

axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

axios.interceptors.request.use(
  (config) => {
    const token = store.state.userInfo?.token;
    token && (config.headers.Authorization = "Bearer " + token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

axios.interceptors.response.use((response) => {
  const { data, status } = response;
  // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
  // 否则的话抛出错误
  if (status === 200) {
    if (data.code == "010") {
      Toast({
        message: data.msg,
        duration: 1000,
        forbidClick: true,
      });
      return;
    }
    if (data.code == "401") {
      Toast({
        message: data.msg,
        duration: 1000,
        forbidClick: true,
      });
      router.replace({
        path: "/login",
        query: {
          redirect: router.currentRoute.fullPath,
        },
      });
      return;
    }
    if (data.code == "000") {
      return Promise.resolve(response);
    }
  }
  return Promise.reject(response);
});

function get(url, params, origin = false) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        if (origin) {
          resolve(res.data);
        } else {
          resolve(res.data?.data || res.data);
        }
      });
  });
}

function post(url, params, origin = false) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.parse(QS.stringify(params))).then((res) => {
      if (origin) {
        resolve(res.data);
      } else {
        resolve(res.data?.data || res.data);
      }
    });
  });
}

function put(url, params, origin = false) {
  return new Promise((resolve, reject) => {
    axios.put(url, QS.stringify(params)).then((res) => {
      if (origin) {
        resolve(res.data);
      } else {
        resolve(res.data?.data || res.data);
      }
    });
  });
}

export default { get, post, put };
