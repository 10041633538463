<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['SET_OPTIONS']),
    getOptions() {
      this.$get('/options').then((res) => {
        this.SET_OPTIONS(res);
      });
    }
  },
  mounted() {
    this.getOptions();
  }
};
</script>

<style lang="scss">
html,
#app,
body {
  height: 100%;
  background: #f4f6f7;
  font-family: PingFangSC-Regular;
}
.hd-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  &-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
