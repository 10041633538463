/*
 * @Author: andrew
 * @Date: 2023-01-16 10:25:49
 * @LastEditTime: 2023-03-27 16:45:57
 * @LastEditors: andrew
 * @Description:
 * @FilePath: \haide_health_h5\src\main.js
 *
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./http";
import {
  Button,
  NavBar,
  Icon,
  DropdownMenu,
  DropdownItem,
  Search,
  PullRefresh,
  Toast,
  List,
  Popup,
  Form,
  Field,
  CountDown,
  Picker,
  NumberKeyboard,
  DatetimePicker,
  Dialog,
  Empty,
} from "vant";
import VueClipboard from 'vue-clipboard2' //复制
Vue.config.productionTip = false;

Vue.prototype.$get = http.get;
Vue.prototype.$post = http.post;
Vue.prototype.$put = http.put;

Vue.use(VueClipboard)
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(CountDown);
Vue.use(Picker);
Vue.use(NumberKeyboard);
Vue.use(DatetimePicker);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Empty);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
